<template>
    <div class="version">
        <p>-- {{ frontName }} --</p>
        <p>前端版本：v{{ frontVersion }}</p>
    </div>
</template>

<script>
    const packageJson = require('@/../package.json')

    export default {
        name: 'version',
        data() {
            return {
                frontVersion: packageJson.version,
                frontName: packageJson.name,
                apiVersion: '',
            }
        },
    }
</script>
<style lang="less" scoped>
    .version {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;

        p {
            font-size: 26px;
        }
    }
</style>
